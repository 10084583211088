@mixin sgx-page-loader-mixin {
  display: block;
  font-size: 1rem;
  width: 5em;
  height: 5em;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f4f4f4,#f4f4f4 50%, #e9e9e9 50%,#e9e9e9);
  background-size: 2em; //needed in order to enable a striped background

  &:after {
    content: attr(data-content);
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 0.5em;
    font-size: 1.2em;
    color: $ui-text-default;
  }
}

sgx-page-loader {
  @include sgx-page-loader-mixin;
}

.sgx-page-loader-line {
  height: 1em;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  &:nth-of-type(odd) {background: $sgx-green;}
  &:nth-of-type(even) {background: $sgx-blue;}
  &:nth-of-type(1) {animation-name: sgx-page-loader-1;}
  &:nth-of-type(2) {animation-name: sgx-page-loader-2;}
  &:nth-of-type(3) {animation-name: sgx-page-loader-3;}
  &:nth-of-type(4) {animation-name: sgx-page-loader-4;}
  &:nth-of-type(5) {animation-name: sgx-page-loader-5;}
}

@keyframes sgx-page-loader-1 {
  0%,20%  {transform: translateX(-100%);}
  70% {transform: translateX(0);}
}

@keyframes sgx-page-loader-2 {
  0%,15%  {transform: translateX(100%);}
  65% {transform: translateX(0);}
}

@keyframes sgx-page-loader-3 {
  0%,10% {transform: translateX(-100%);}
  60% {transform: translateX(0);}
}

@keyframes sgx-page-loader-4 {
  0%,5% {transform: translateX(100%);}
  55% {transform: translateX(0);}
}

@keyframes sgx-page-loader-5 {
  0% {transform: translateX(-100%);}
  50% {transform: translateX(0);}
}
