/* DEPENDENCIES */

@import "sgx-base-code/styles/sgx-colors";
@import "sgx-base-code/styles/sgx-variables";
@import "../components/sgx-page-loader/sgx-page-loader.scss";

/* CONTAINER */

sgx-page-app-loader {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  z-index: 2000;

    sgx-page-loader {
      position: absolute;
      top: 50vh;
      left: 50vw;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
}

.sgx-page-app-loader--hidden {
  display: none;
}

/* LOADER */

sgx-page-loader {
  @include sgx-page-loader-mixin;
}
